import { memo } from '../../../util/memo';
import { useTheme } from '@mui/material/styles';
import { useMemo } from 'react';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useRealtimeCount } from '../../../hooks/useRealtimeCount';
import { useLivestream } from '../../../contexts/LivestreamContext';
import Button from '@mui/material/Button';
import { toPresencePath } from '../../../../functions/src/util/presence/toPresencePath';

export type ViewerCountButtonProps = {
  onClick: () => void;
};

const ViewerCountButtonUnmemoized = ({ onClick }: ViewerCountButtonProps) => {
  const theme = useTheme();
  const { isLive, playbackId } = useLivestream();
  const liveViewers = useRealtimeCount(
    toPresencePath({
      elementId: playbackId,
      choice: 'liveViewerCount',
    }),
  );

  const viewerCountButton = useMemo(() => {
    return (
      !!liveViewers &&
      isLive && (
        <Button
          variant="contained"
          startIcon={<VisibilityOutlinedIcon />}
          onClick={onClick}
          sx={{
            background: theme.palette.background.elevation[12],
            whiteSpace: 'nowrap',
            p: '2px 12px',
            '&:hover': {
              background: `${theme.palette.background.elevation[18]}`,
            },
          }}
        >
          {`${liveViewers} watching`}
        </Button>
      )
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveViewers, isLive, onClick]);

  // eslint-disable-next-line @blumintinc/blumint/no-useless-fragment
  return <>{viewerCountButton}</>;
};

export const ViewerCountButton = memo(ViewerCountButtonUnmemoized);
