import { useMemo } from 'react';
import { memo } from '../../../util/memo';
import Stack from '@mui/material/Stack';
import { PublishTournamentButton } from './PublishTournamentButton';
import { DeleteTournamentButton } from './DeleteTournamentButton';
import {
  TOURNAMENT_PHASE_EDITABLE,
  Tournament,
} from '../../../../functions/src/types/firestore/Game/Tournament';
import { Optional } from 'utility-types';
import { EditButton } from '../../buttons/EditButton';
import { TabsRouted } from '../../routing/TabsRouted';
import { useIsEditingTournament } from '../../../hooks/organizer/useIsEditingTournament';

export type EditorPanelProps = Optional<Pick<Tournament, 'phase'>>;

export const EditorPanel = memo(function EditorPanelUnmemoized({
  phase,
}: EditorPanelProps) {
  const { isEditing, toggleMode, state } = useIsEditingTournament(phase);
  const isRegistration = phase === 'registration';
  const isEditablePhase = TOURNAMENT_PHASE_EDITABLE.some((value) => {
    return value === phase;
  });

  const tabs = useMemo(() => {
    return [
      {
        value: 'primary',
        customization: {
          label: 'Edit',
        },
      },
      {
        value: 'secondary',
        customization: {
          label: 'Preview',
        },
      },
    ];
  }, []);

  const controls = useMemo(() => {
    return (
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        gap={2}
        spacing={2}
        alignItems="center"
      >
        {isRegistration && (
          <EditButton isEditing={isEditing} onEditChange={toggleMode} />
        )}
        {isEditablePhase && <DeleteTournamentButton />}
        {!isRegistration && (
          <>
            <PublishTournamentButton />
            <TabsRouted
              tabs={tabs}
              variant="toggle-button"
              sx={{ minWidth: '300px', height: '42px' }}
              state={state}
            />
          </>
        )}
      </Stack>
    );
  }, [isRegistration, isEditing, toggleMode, isEditablePhase, tabs, state]);

  return (
    <Stack
      width="100%"
      justifyContent="right"
      direction={{ xs: 'column', md: 'row' }}
      alignItems="center"
      sx={(theme) => {
        return {
          background: theme.palette.background.elevationSolid[3],
          boxShadow: `20px 0px 0 10px ${theme.palette.background.elevationSolid[3]}, -20px 0px 0 10px ${theme.palette.background.elevationSolid[3]}`,
        };
      }}
      py={2}
      gap={2}
    >
      {controls}
    </Stack>
  );
});
/* <ConnectedUsers />
              <Tooltip
                arrow
                title="Invite your team members and edit this tournament together. When you're happy with your changes, you can publish your tournament."
                placement="bottom"
              >
                <div>
                  <AddCollaboratorButton />
                </div>
              </Tooltip> 
*/
