import CircleIcon from '@mui/icons-material/CircleRounded';
import { memo } from '../../../util/memo';
import Button from '@mui/material/Button';
import { useMemo } from 'react';
import { useTheme, SxProps } from '@mui/material/styles';
import { withOpacity } from '../../../util/styles/withOpacity';
import {
  useLivestreamConfigurationDialog,
  UseLivestreamConfigurationDialogParams,
} from '../../../hooks/livestream/useLivestreamConfigurationDialog';

export type LivestreamConfigurationButtonProps =
  UseLivestreamConfigurationDialogParams & { sx?: SxProps };

const LivestreamConfigurationButtonUnmemoized = ({
  channelId,
  onClose,
  sx = {},
}: LivestreamConfigurationButtonProps) => {
  const theme = useTheme();
  const { open } = useLivestreamConfigurationDialog({
    channelId,
    onClose,
  });

  const circleIcon = useMemo(() => {
    return (
      <CircleIcon
        sx={{
          width: '12px',
          height: '12px',
        }}
      />
    );
  }, []);

  return (
    <Button
      onClick={open}
      variant="text"
      color="error"
      sx={{
        height: '28px',
        whiteSpace: 'nowrap',
        border: `1px solid ${withOpacity(theme.palette.error.main, 0.5)}`,
        ':hover': {
          background: theme.palette.error.main,
          color: theme.palette.text.primary,
        },
        ...sx,
      }}
      startIcon={circleIcon}
    >
      Go Live
    </Button>
  );
};

export const LivestreamConfigurationButton = memo(
  LivestreamConfigurationButtonUnmemoized,
);
