import { useMemo } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { isAdmin } from '../../../../functions/src/types/firestore/User/util';
import {
  ADMIN_FILTERS,
  GUEST_FILTERS,
  normalUserFilters,
} from '../../../../functions/src/util/algolia/config/events';
import { onlyContributor } from '../../../../functions/src/util/algolia/config/events/organizer';

export const useUserEventsFilters = (groupNumberBased?: string) => {
  const { user } = useAuth();

  return useMemo(() => {
    const getBaseFilters = () => {
      if (!user?.uid) return GUEST_FILTERS;
      if (isAdmin(user.email)) return ADMIN_FILTERS;
      return normalUserFilters(user.uid);
    };

    const baseFilters = getBaseFilters();

    const combineWithGuildFilters = (filters: string) => {
      if (isAdmin(user?.email)) {
        return groupNumberBased
          ? guildContributorFilters(groupNumberBased)
          : ADMIN_FILTERS;
      }
      if (!groupNumberBased) {
        return filters;
      }
      if (Number.isNaN(Number(groupNumberBased))) {
        const onlyProfile = onlyContributor(groupNumberBased);
        return filters ? `${filters} AND ${onlyProfile}` : onlyProfile;
      }
      return filters
        ? `(${filters}) AND ${guildContributorFilters(groupNumberBased)}`
        : filters;
    };

    return combineWithGuildFilters(baseFilters);
  }, [groupNumberBased, user?.email, user?.uid]);
};

export const guildContributorFilters = (groupNumberBased: string) => {
  return `roles.contributor:${groupNumberBased}`;
};
