import { useContext, useMemo } from 'react';
import { memo } from '../../util/memo';
import { useDateFormatter } from 'src/hooks/useDateFormatter';
import { TournamentContext } from '../../contexts/docs/TournamentContext';
import { GradientTypography } from '../gradients/GradientTypography';
import { TournamentPayout } from '../cards/tournament/TournamentPayout';
import Stack from '@mui/material/Stack';
import { TournamentLivestreamButton } from '../livestream/mux/TournamentLivestreamButton';
import { useAuth } from '../../contexts/AuthContext';
import { isStreamer } from '../../util/roles/isStreamer';
import { TournamentViewStreamButton } from '../livestream/mux/TournamentViewStreamButton';
import { extractPlaybackIds } from '../../../functions/src/util/mux/extractPlaybackIds';
import { LivestreamProvider } from '../../contexts/LivestreamContext';
import { ELLIPSIS_SX } from '../../util/string';

function TournamentTitleUnmemoized() {
  const { uid } = useAuth();
  const { title, date, id, roles, playbacksDownloadable } =
    useContext(TournamentContext);
  const formatDate = useDateFormatter();
  const dateFormatted = formatDate(date);
  const canStream = isStreamer(roles, uid || undefined);
  const playbackIdLatest = extractPlaybackIds(playbacksDownloadable)[0];

  const viewerCountButton = useMemo(() => {
    return (
      !!playbackIdLatest && (
        <LivestreamProvider
          playbackId={playbackIdLatest}
          playbacksDownloadable={playbacksDownloadable}
        >
          <TournamentViewStreamButton tournamentId={id} />
        </LivestreamProvider>
      )
    );
  }, [playbackIdLatest, playbacksDownloadable, id]);

  const endComponent = useMemo(() => {
    return canStream ? (
      <TournamentLivestreamButton tournamentId={id} />
    ) : (
      viewerCountButton
    );
  }, [canStream, id, viewerCountButton]);

  return (
    <Stack
      spacing={2}
      direction="row"
      alignItems="center"
      justifyContent="center"
      flexWrap="wrap"
      gap={2}
      textAlign="center"
    >
      <Stack
        spacing={1}
        direction="row"
        alignItems="center"
        justifyContent="center"
        flexWrap="wrap"
        textAlign="center"
        width="100%"
      >
        <TournamentPayout variant="h5" sx={{ fontWeight: '700' }} />
        <GradientTypography
          variant="h5"
          gradientColor={'primary.vertical'}
          component="div"
          textTransform={'uppercase'}
          sx={ELLIPSIS_SX}
        >
          {title}
        </GradientTypography>
        <GradientTypography
          variant="h5"
          gradientColor={'primary.vertical'}
          component="div"
          textTransform={'uppercase'}
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          {dateFormatted}
        </GradientTypography>
      </Stack>
      {endComponent}
    </Stack>
  );
}

export const TournamentTitle = memo(TournamentTitleUnmemoized);
